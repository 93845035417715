import {
  createSender,
  createReceiver,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'


const MODEL = 'orders'

const ORDERS_CHANGE = prefixAPI('/change/', MODEL)
const ORDERS_COMMIT = prefixAPI('/commit/', MODEL)
const ORDERS_PAY = prefixAPI('/payment/online/', MODEL)
const ORDERS_CASH_PAY = prefixAPI('/payment/cash/', MODEL)
const ORDERS_DEVICE_BIND = prefixAPI('/{id}/device-bind/', MODEL)

export const ordersChangeReceiver = createSender(ORDERS_CHANGE)
export const ordersCommitReceiver = createSender(ORDERS_COMMIT)
export const ordersPayReceiver = createSender(ORDERS_PAY)
export const ordersCashPayReceiver = createSender(ORDERS_CASH_PAY)
export const ordersDeviceBindReceiver = createSender(ORDERS_DEVICE_BIND)
